var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("goback", { attrs: { msg: _vm.msg, url: "/" } }),
      _vm._l(_vm.list, function(item) {
        return _c("img", {
          staticStyle: { width: "100%", height: "auto" },
          attrs: { src: item.url }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }