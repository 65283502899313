<template>
    <div>
      <goback :msg='msg' url="/"></goback>
      <img style="width: 100%; height: auto" v-for="item in list" :src="item.url">
    </div>
</template>

<script>
import {pdfFiles} from "@/api";
import goback from '../goBack/index.vue'
export default {
  name: '',
  data() {
    return {
        list:[]
    }
  },
   components: {
     goback
   },
   watch:{

   },
   props: {

  },
  created() {
    pdfFiles(this.$route.query.id).then((res) => {
      this.list= res

    })
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>



</style>
